import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import promotion from "../public/img/promotion-1.png";
import event from "../public/img/event-1.png";
import destination from "../public/img/destination-1.png";
import dotFull from "../public/img/dotfull.png";

export function Whatsnew() {
  const [data, setData] = useState([]);
  const [news, setNews] = useState([]);

  const dataEndpoint = () =>
    `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/info-news`;

  useEffect(() => {
    fetch(dataEndpoint())
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setNews(res.data.news);
      });
  }, []);
  return (
    <div className="relative">
      <div className="grid my-6">
        <div className="relative z-0 self-center px-2 sm:px-4 md:px-16 lg:px-20 py-10 text-center sm:text-center md:text-left lg:text-left">
          <div className="absolute top-0 sm:-top-4 md:top-1 lg:top-2 left-40 sm:left-40 md:left-8 lg:left-8 z-0 rounded-full h-28 w-28 flex items-center justify-center pink-more"></div>
          <div className="relative z-10 w-3/4 w-full sm:w-full md:w-full lg:w-full mx-auto sm:mx-auto md:mx-0 lg:mx-0">
            <h1 className="text-xl sm:text-xl md:text-2xl lg:text-4xl prata purple-more font-normal mb-2">
              What’s New
            </h1>
            {/* <p className="text-sm sm:text-sm md:text-base lg:text-base sora black-more font-light">
              {data.description}
            </p> */}
            <div
              className="text-sm sm:text-sm md:text-base lg:text-base sora black-more font-light"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="relative z-20 grid md:grid-cols-3 lg:grid-cols-3 gap-4 px-20 px-12 sm:px-16 md:px-10 lg:px-32 mb-4">
        {news.map((data, key) => (
          <div key={key} className="purple-mix flex justify-center text-center">
            <div className="grid">
              <div className="grid">
                <p className="text-base text-white sora font-semibold mb-1.5 mt-4 capitalize">
                  {data.variant === "promotions"
                    ? "Rewards & Benefits"
                    : data.variant}
                </p>
                <p className="w-8 border-pink mb-3 m-auto"></p>
              </div>
              <div className="grid relative">
                <img src={data.image} alt={data.variant} className="w-full" />
                <div className="hidden sm:block absolute top-0 left-0 bottom-0 right-0 purple-mix opacity-0 hover:opacity-90">
                  <div className="grid h-full text-center self-center">
                    {/* <p className="text-sm md:text-base lg:text-base text-white sora font-medium self-center pt-0 xl:pt-5 mx-3">
                      {data.description}
                    </p> */}
                    <div
                      className="text-sm md:text-base lg:text-base text-white sora font-medium self-center pt-0 xl:pt-5 mx-3"
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    ></div>
                    <Link
                      href={{
                        pathname:
                          data.variant == "promotions"
                            ? `/experience/promotions`
                            : data.variant == "event"
                            ? `/experience/events`
                            : data.variant == "destination"
                            ? `/experience/destinations`
                            : null,
                      }}
                    >
                      <button
                        type="button"
                        className="w-9/12 lg:w-1/2 m-auto mt-0 text-xs sm:text-xs md:text-xs lg:text-base text-center text-white sora font-extralight border py-2 px-4"
                      >
                        See more &nbsp;&nbsp;&rarr;
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="grid px-16 sm:px-16 md:px-20 lg:px-24 my-4 justify-center">
        <Link href="/experience">
          <button
            type="button"
            className="text-xs sm:text-sm lg:text-base text-center text-white sora font-extralight purple-dart py-2 px-10"
          >
            View All
          </button>
        </Link>
      </div>
      <div className="absolute z-10 bottom-0 sm:top-36 md:top-36 lg:top-36 -right-6 sm:-right-2.5 md:right-2 lg:right-14">
        <img
          src="/img/dotfull.png"
          alt="Dot"
          width={149}
          height={149}
          className=""
        />
      </div>
    </div>
  );
}
