import { useCallback, useRef, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import search from '../public/img/search.png'

export function Search() {

    const searchRef = useRef(null)
    const [query, setQuery] = useState('')
    const [active, setActive] = useState(false)
    const [results, setResults] = useState([])

    const searchEndpoint = (query) => `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/all?search=${query}`

    const onChange = useCallback((event) => {
        const query = event.target.value;
        setQuery(query) 
        query == '' ? setActive(false) : setActive(true)
        if (query.length) {
          fetch(searchEndpoint(query))
            .then(res => res.json())
            .then(res => {
              setResults(res.data.result)   
            //   console.log(res.data.result)   
            })
        } else {
          setResults([])
        }
      }, [])
    
    const onFocus = useCallback(() => {
        // setActive(true)
        window.addEventListener('click', onClick)
    }, [])

    const onClick = useCallback((event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
            setActive(false)
            window.removeEventListener('click', onClick)
        }
    }, [])

    function moveDetail(data) { 
        if (typeof window !== "undefined") { 
            data.variant == 'promo' ?
                window.localStorage.setItem("promotion", JSON.stringify(data))
            :
            data.variant == 'event' ?
                window.localStorage.setItem("event", JSON.stringify(data))
            :
            data.variant == 'tenant' ? 
                window.localStorage.setItem("tenant", JSON.stringify(data))
            :
            null  
        }
    }

    return (
        <div>
            <div className="relative flex-row border-b-1 border-purple w-2/3 sm:w-2/3 md:w-2/5 lg:w-1/4 px-2 float-right hidden sm:hidden md:block lg:block">
                <img src='/img/search.png' alt="Search" className="w-[16px] inline-block"/>
                <input
                    className="text-sm bg-transparent focus:outline-none purple-more px-2 py-1"
                    onChange={onChange}
                    onFocus={onFocus}
                    placeholder='Search'
                    type='text'
                    value={query}
                /> 
                {   active ?
                    <div className={`grid z-30 absolute -bottom-15 left-0 right-0 border-result bg-white mt-0.5 overflow-y-auto`}>
                        {   results.length > 0 ?
                                results.map((data, key) => (
                                    <Link key={key} href={{ 
                                                pathname: 
                                                    data.variant == 'promo' ? `/experience/promotions/[pid]` : 
                                                    data.variant == 'event' ? `/experience/events/[eid]` :
                                                    data.variant == 'tenant' ? `/directions/[did]` : null , 
                                                query: data
                                        }}  as={
                                                    data.variant == 'promo' ? `/experience/promotions/${(data.name.split(' ').join('-')).toLowerCase()}` : 
                                                    data.variant == 'event' ? `/experience/events/${(data.name.split(' ').join('-')).toLowerCase()}` :
                                                    data.variant == 'tenant' ? `/directions/${(data.name.split(' ').join('-')).toLowerCase()}` : null 
                                        }>
                                        <button onClick={() => moveDetail(data)} className="text-sm purple-more px-8 py-1.5 text-left h-[35px]">{data.name}</button>
                                    </Link>
                                )) 
                            :
                            <p className="text-sm purple-more px-8 py-1.5 text-left">Tidak ada hasil</p>
                        } 
                    </div>
                    : null 
                } 
            </div> 
        </div>
    )
}