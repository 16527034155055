// import 'tailwindcss/tailwind.css'
import '../styles/global.css'
import 'swiper/swiper-bundle.css'
import 'next-pagination/dist/index.css'
import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'; 

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const [pageLoading, setPageLoading] = useState(false)
  useEffect(() => {
    const handleStart = () => { setPageLoading(true); }
    const handleComplete = () => { setPageLoading(false); }

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  // return <Component {...pageProps} />
  return ( 
    <div>
    { pageLoading 
      ? (
          <div className="flex purple-dart w-full h-screen justify-center ">
            <div className="grid">
              <img src="/img/white.png" className="w-[254px] h-[261px] mx-auto mt-auto mb-4"/>
              <img src="/img/loading.gif" className="w-[200px] h-[200px] mx-auto mb-auto mt-4"/>
            </div>
          </div>
        )
      : <Component {...pageProps} />
    } 
    </div>

  )
}

export default MyApp
