import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";
import logo from "../public/img/black.png";
import menu from "../public/img/menu.png";
import search from "../public/img/search.png";
import close from "../public/img/close.png";

const Search = dynamic(() => import("./search").then((mod) => mod.Search));

const Sidebar = dynamic(() => import("./sidebar").then((mod) => mod.Sidebar));

export function HeaderSlim() {
  const [showResults, setShowResults] = useState(false);
  const [data, setData] = useState([]);

  const dataEndpoint = () =>
    `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/company`;

  useEffect(() => {
    fetch(dataEndpoint())
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        // console.log('z', res.data)
      });
    // let scrollpos = window.scrollY;
    // const header = document.querySelector("#trig");
    // const header_height = header.offsetHeight;

    // const add_class_on_scroll = () => header.classList.add("bg-white", "fixed")
    // const remove_class_on_scroll = () => header.classList.remove("bg-transparant")

    // window.addEventListener('scroll', function() {
    //     scrollpos = window.scrollY;

    //     if (scrollpos >= header_height) { add_class_on_scroll() }
    //     else { remove_class_on_scroll() }

    //     // console.log(scrollpos)
    // })
  }, []);
  const toggleClick = () => setShowResults((wasOpened) => !wasOpened);

  return (
    <div className="sticky top-0 left-0 right-0 z-30 bg-white">
      <div className="relative flex grid-cols-2 px-8 sm:px-8 md:px-16 lg:px-20 py-6">
        <div className="">
          <Link href="/">
            <a className="logo">
              <img
                src={data.company_logo}
                alt="Logo"
                className="w-12 sm:w-12 md:w-18 lg:w-22"
              />{" "}
            </a>
          </Link>
        </div>
        <div className="flex-1">
          <div className="relative flex-row">
            <button onClick={toggleClick} className="float-right pl-6 py-2">
              <img
                src="/img/menu.png"
                alt="Menu"
                width={24}
                height={24}
                className=""
              />
            </button>
            {showResults ? (
              <button
                onClick={toggleClick}
                className="fixed z-50 right-12 top-11"
              >
                <img
                  src="/img/close.png"
                  alt="Close"
                  width={24}
                  height={24}
                  className=""
                />
              </button>
            ) : null}
            <Search />
            {/* <button className="float-right pl-6 py-2.5 block sm:block md:hidden lg:hidden">
                            <Image src={search} alt="Search" width={16} height={16} className=""/>
                        </button>   */}
          </div>
        </div>
      </div>
      {showResults ? <Sidebar /> : null}
    </div>
  );
}
