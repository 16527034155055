import Image from "next/image";
import { useState, useEffect } from "react";
import taxi from "../public/img/frontal-taxi-cab 1.png";
import wifi from "../public/img/frontal-taxi-cab 2.png";
import kado from "../public/img/frontal-taxi-cab 3.png";
import mesjid from "../public/img/frontal-taxi-cab 4.png";
import dotFull from "../public/img/dotfull.png";

export function Facilities() {
  const [data, setData] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const dataEndpoint = () =>
    `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v2/info-facility`;

  useEffect(() => {
    fetch(dataEndpoint())
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setFacilities(res.data.facility);
        // console.log('a', res.data)
      });
  }, []);
  return (
    <div className="relative grid lg:grid-cols-2 pink-dart">
      <div className="relative px-20 justify-self-center sm:justify-self-start self-center px-12 py-12 sm:py-28 md:py-28 lg:py-28 text-center sm:text-center md:text-center lg:text-left">
        <div className="absolute top-4 sm:top-2 md:top-16 lg:top-20 left-40 sm:left-44 md:left-72 lg:left-8 z-0 rounded-full h-24 w-24 flex items-center justify-center white-more"></div>
        <div className="relative z-10">
          <h1 className="text-xl sm:text-xl lg:text-4xl prata text-white font-normal mb-2">
            FACILITIES
          </h1>
          {/* <p className="text-xs sm:text-sm lg:text-base sora text-white font-light">
            {data.description}
          </p> */}
          <div
            className="text-xs sm:text-sm lg:text-base sora text-white font-light"
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          ></div>
        </div>
      </div>
      <div className="grid grid-cols-2 purple-dart justify-self-center self-center px-6 sm:px-6 md:px-12 lg:px-8 2xl:px-24 py-12 sm:py-14 md:py-20 lg:py-24">
        {facilities.map((data, key) => (
          <div
            key={key}
            className="grid grid-cols-1 sm:grid-cols-2 mb-10 mx-3 sm:mx-0"
          >
            <div className="text-center sm:text-center md:text-center lg:text-left justify-self-center self-center mx-6">
              <img
                src={data.faciliti_image}
                alt={data.faciliti_description}
                className="w-[36px] m-auto"
              />
            </div>
            <div className="text-center sm:text-center md:text-left lg:text-left self-center">
              <span className="text-sm sm:text-sm lg:text-base text-white font-semibold sora">
                {data.faciliti_name}
              </span>
              {/* <p className="text-xs sm:text-sm lg:text-sm text-white font-extralight sora">
                {data.faciliti_description}
              </p> */}
              <div
                className="text-xs sm:text-sm lg:text-sm text-white font-extralight sora"
                dangerouslySetInnerHTML={{
                  __html: data.faciliti_description,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      <div className="absolute z-20 -bottom-20 sm:-bottom-20 md:-bottom-20 lg:-top-20 right-36 sm:right-72 md:right-80 lg:-right-20">
        <img
          src="/img/dotfull.png"
          alt="Dot"
          width={149}
          height={149}
          className=""
        />
      </div>
    </div>
  );
}
