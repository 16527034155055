import Head from 'next/head'
import { useState, useEffect } from 'react' 

export default function Layout({ children, title = '23 Paskal', }){
    
    const [data, setData] = useState([])
    const dataEndpoint = () => `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/company`
    
    useEffect(() => { 
        fetch(dataEndpoint())
            .then(res => res.json())
            .then(res => {
                if (res.status != 400) {
                  setData(res.data);
                  // console.log(res)
                } else {
                  setData([]);
                }
            })  
    }, []); 
  return (
    <div className="relative">
        <Head>
            <title>{title}</title>
            {/* <link rel="icon" href="/favicon.ico" />  */}
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/> 
            <link href="https://fonts.googleapis.com/css2?family=Prata&display=swap" rel="stylesheet"/> 
            <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600&display=swap" rel="stylesheet"/>
            <meta name="msapplication-TileColor" content="#da532c"/>
            <meta name="theme-color" content="#ffffff"/>
            <meta charSet="UTF-8"/>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta name={title} content={title}/>
            <meta name="description" content={data.company_description}/>
            
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={data.domain_name}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={data.company_description}/>
            <meta property="og:image" content={data.company_logo}/>

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content={data.domain_name}/>
            <meta property="twitter:title" content={title}/>
            <meta property="twitter:description" content={data.company_description}/>
            <meta property="twitter:image" content={data.company_logo}/>
        </Head>
        {children}
    </div>
    )
}