import Image from "next/image";
import Link from "next/link";
import useSWR from "swr";
import { useState, useEffect } from "react";
import cornerstone from "../public/img/cornerstone.png";
import sos1 from "../public/img/sos1.png";
import sos2 from "../public/img/sos2.png";
import sos3 from "../public/img/sos3.png";
import sos4 from "../public/img/sos4.png";
import sos5 from "../public/img/sos5.png";
import sos6 from "../public/img/sos6.png";
import call from "../public/img/call.png";
import whatsapp from "../public/img/whatsapp.png";
import Moment from "moment";

export function Foot() {
  const getYear = new Date().getFullYear();

  const [data, setData] = useState([]);
  const [medsos, setMedsos] = useState([]);
  const [email, setEmail] = useState("");
  const [getWhatsapp, setGetWhatsapp] = useState("");

  const dataEndpoint = () =>
    `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/company`;
  const getMedSos = () =>
    `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/media-social`;

  useEffect(() => {
    fetch(dataEndpoint())
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setGetWhatsapp(res.data.company_cellular.substring(1));
        // console.log('a', res.data)
      });
    fetch(getMedSos())
      .then((res) => res.json())
      .then((res) => {
        setMedsos(res.data);
      });
  }, []);

  const newsLetter = async (event) => {
    event.preventDefault(); // don't redirect the page
    // where we'll add our form logic
    // console.log(email)
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/subscribe`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );
    const data = await res.json();
    if (data.status == 200) {
      alert("Berhasil Subscribe");
    }
  };

  return (
    <div className="purple-dart">
      <div className="grid lg:grid-cols-3 mx-6 sm:mx-6 md:mx-10 lg:mx-10 mt-16 mb-12">
        <div className="mb-6">
          <p className="text-base text-white prata leading-10 font-normal">
            Recieve Our Newsletter
          </p>
          <div className="grid mb-8">
            <form onSubmit={newsLetter}>
              <input
                id="email-address"
                name="email"
                type="email"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email here"
                className="text-sm sm:text-sm lg:text-md focus:outline-none w-3/5 py-2 px-4 mr-5"
              />
              <button
                type="submit"
                className="text-sm sm:text-sm lg:text-md text-center text-white sora font-extralight pink-dart w-2/6 py-2 px-4"
              >
                Submit
              </button>
            </form>
          </div>
          <p className="text-base text-white prata leading-10 font-normal">
            Contact Us
          </p>
          <div className="flex my-2">
            <img
              src="/img/call.png"
              alt="Telepon"
              className="w-[20px] h-[20px]"
            />
            <a href={`tel:+${data.company_phone}`}>
              <span className="text-sm text-white sora font-light pl-2">
                {data.company_phone}
              </span>
            </a>
            <div className="border-l border-opacity-50 mx-4"></div>
            <img
              src="/img/whatsapp.png"
              alt="Whatsapp"
              className="w-[20px] h-[20px]"
            />
            <a
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=+62${getWhatsapp}&amp;text=Hello, I have a question about this."`}
            >
              <span className="text-sm text-white sora font-light pl-2 ">
                {data.company_cellular}
              </span>
            </a>
          </div>
          <p className="text-sm text-white sora font-light">
            {data.company_address}
          </p>
          <div className="flex mt-4 mb-2">
            <span className="text-base text-sm text-white sora font-semibold">
              YOBO by Qraved
            </span>
          </div>
          <div className="flex mt-4 mb-2">
            <span className="text-sm text-white sora font-light self-center mr-4">
              Managed By
            </span>
            <a target="_blank" href={"https://cornerstone.id/"}>
              <img
                src="/img/logoHighlight.png"
                alt="Cornerstone"
                className="w-[213px] h-[46px]"
              />
            </a>
          </div>
        </div>
        <div className="mb-6 lg:ml-16">
          <p className="text-base text-white prata font-normal leading-10">
            Operational Hour
          </p>
          <p className="text-sm text-white sora font-semibold">Open :</p>
          <p className="text-sm text-white sora font-light mb-4">
            {data.company_daily_open} - {data.company_daily_close}
          </p>
          <p className="text-sm text-white sora font-semibold">
            Weekend & Public Holiday :
          </p>
          <p className="text-sm text-white sora font-light">
            {data.company_holiday_open} - {data.company_holiday_close}
          </p>
        </div>
        <div className="mb-6">
          <p className="text-base text-white prata font-normal leading-10">
            Follow Us
          </p>
          <div className="flex">
            {medsos.map((data, key) => (
              <Link key={key} href={data.sosmed_link}>
                <a className="mr-2" target="_blank">
                  <img
                    src={data.sosmed_image}
                    alt={data.sosmed_name}
                    className="w-[36px] h-[36px]"
                  />{" "}
                </a>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="grid hidden sm:hidden md:block lg:block xl:block 2xl:block 4xl:block">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.7935166578964!2d107.59235011455034!3d-6.9152732950033675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e725803e9a63%3A0x87fcd3eb310e8e7a!2s23%20Paskal%20Shopping%20Center!5e0!3m2!1sen!2sid!4v1627374541921!5m2!1sen!2sid"
          className="w-full h-[299px] sm:h-[300px]"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="grid grid-cols-2 pink-dart">
        <div className="mx-10 mt-2">
          <img
            src="/img/cornerstone.png"
            alt="Cornerstone"
            width={107}
            height={31}
            className=""
          />
        </div>
        <div className="mx-10 self-center">
          <p className="text-xs sm:text-xs lg:text-sm text-white text-right copyright">
            Copyright {getYear}
          </p>
        </div>
      </div>
    </div>
  );
}
