import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import banner from "../public/img/banner-2.png";
import dotFull from "../public/img/dotfull.png";

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

export function Promotion() {
  const [data, setData] = useState([]);
  const [promo, setPromo] = useState([]);

  const dataEndpoint = () =>
    `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/promotion-info`;

  useEffect(() => {
    fetch(dataEndpoint())
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setPromo(res.data.news);
        // console.log('a', res.data.promotions)
      });
  }, []);
  return (
    <div className="grid relative md:grid-cols-2 lg:grid-cols-2 purple-dart px-12 sm:px-16 md:px-20 lg:px-32 py-12 sm:py-12 md:py-12 lg:py-20 justify-center">
      <div className="justify-self-center sm:justify-self-start self-center text-center sm:text-center md:text-left lg:text-left pb-28 sm:pb-20 md:pb-0 lg:pb-0 pr-0 sm:pr-0 md:pr-10 lg:pr-16">
        <h1 className="text-xl sm:text-xl lg:text-4xl prata text-white font-normal mb-2">
          Promotions
        </h1>
        {/* <p className="text-xs sm:text-sm lg:text-base sora text-white font-light mb-8">
          {data.description}
        </p> */}
        <div
          className="text-xs sm:text-sm lg:text-base sora text-white font-light mb-8"
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        ></div>
        <Link href="/experience/promotions">
          <button
            type="button"
            className="text-xs sm:text-sm lg:text-base text-center text-white sora font-extralight pink-dart py-2 px-10 mb-10"
          >
            View All
          </button>
        </Link>
      </div>
      <div className="relative z-20">
        <div className="absolute -top-28 sm:top-4 md:-top-16 lg:-top-28 xl:-top-28 2xl:-top-32 left-0 sm:-left-4 md:left-0 lg:left-0 right-0">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            pagination={{ clickable: true }}
            className="!w-[220px] md:!w-[335px] lg:!w-[455px] !h-[255px] md:!h-[370px] lg:!h-[490px] promotion"
          >
            {promo.map((data, key) => (
              <SwiperSlide key={key}>
                <img
                  src={data.promotion_images[0]}
                  alt="Promo"
                  className="w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="absolute z-10 -bottom-20 sm:-bottom-20 md:-bottom-20 lg:-bottom-20 -left-20 sm:-left-20 md:-left-20 lg:-left-20">
        <img
          src="/img/dotfull.png"
          alt="Dot"
          width={149}
          height={149}
          className=""
        />
      </div>
    </div>
  );
}
