import dynamic from 'next/dynamic'
import Layout from '../components/layout'
import useSWR from 'swr'
import { useEffect } from 'react'

const GetFoot = dynamic(() =>
  import('../components/foot').then((mod) => mod.Foot)
)
const Header = dynamic(() =>
  import('../components/header').then((mod) => mod.Header)
)
const Whatsnew = dynamic(() =>
  import('../components/whatsnew').then((mod) => mod.Whatsnew)
)
const Facilities = dynamic(() =>
  import('../components/facilities').then((mod) => mod.Facilities)
)
const Banner = dynamic(() =>
  import('../components/banner').then((mod) => mod.Banner)
)
const Promotion = dynamic(() =>
  import('../components/promotion').then((mod) => mod.Promotion)
)
const HeaderSlim = dynamic(() =>
  import('../components/headerSlim').then((mod) => mod.HeaderSlim)
)

export default function Home() {   

  const dataEndpoint = () => `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/tenants?search=&current_page=1&per_page=12`
  
  useEffect(() => { 
      fetch(dataEndpoint())
          .then(res => res.json())
          .then(res => { 
              if (typeof window !== "undefined") { 
                window.localStorage.setItem("pagination", JSON.stringify(res.data));
              }     
          })
  }, []); 

  return (
    <Layout> 
      <HeaderSlim/>
      <main className="relative overflow-hidden">
        {/* <div className="grid w-full"> */}
          <Header/>
        {/* </div>  */}
        <div className="grid w-full mb-10">
          <Whatsnew/>
        </div>
        <div className="grid w-full my-32">
          <Promotion/>
        </div>
        <div className="grid w-full mt-60 sm:mt-0 md:mt-0 lg:mt-0 mb-24 ">
          <Banner/>
        </div>
        <div className="grid w-full mb-24">
          <Facilities/>
        </div>
      </main>

      <footer className="grid w-full">
        <GetFoot/>
      </footer>
    </Layout>
  )
} 