import Image from 'next/image'
import { useState, useEffect } from 'react' 
import bannerCom from '../public/img/banner-1.png'

export function Banner() {
    const [data, setData] = useState([])

    const dataEndpoint = () => `${process.env.NEXT_PUBLIC_ENV_PRODUCTION}v1/company`
    
    useEffect(() => { 
        fetch(dataEndpoint())
            .then(res => res.json())
            .then(res => {
                if(res.status != 400){
                    setData(res.data)  
                } else {
                    setData([])
                }   
                // console.log('a', res.data)
            })
    }, []); 
    return (
        <div className="object-cover w-full px-6 text-center">
            <a href="https://malltogo.izy.ai/" target="_blank">
                <img src={data.company_banner} alt="Banner" className="w-full"/>
            </a>
        </div>
    ) 
}